// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
import * as Msal from '@azure/msal-browser';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { B2C_DOMAIN, B2C_ID_TOKEN_ACR, CHANGE_PASSWORD_AUTHORITY, CLIENT_ID, EDIT_PROFILE_AUTHORITY, LOGIN_REDIRECT_URL, MERLIN, OKTA_CUSTOM_SSO_AUTHORITY, REFRESH_TOKEN_SCOPES, RESET_PASSWORD_AUTHORITY, SIGN_IN_AUTHORITY } from '../common/constants';
import { userEventSender } from '../common/event';
import { displayErrorPage, extractHostnameFromUrl } from '../common/util';
import { GetUserInfoRes } from '../services/model/user';
import Services from '../services/services';
import { userStore } from '../store';


function loggerCallback(logLevel: Msal.LogLevel, message: string) {
  console.log(logLevel, message);
}

type IdTokenClaims = {
  acr: string;
  email: string;
  ['organization_id']: string;
  mobile: string;
  idp: string;
  ['idp_access_token']: string;
  ['other_mails']: string[];
  iss: string;
}

// const logger = new Msal.Logger({ logLevel: Msal.LogLevel.Warning, loggerCallback });
const state: { stopLoopingRedirect: boolean; accessToken: undefined | Msal.AuthenticationResult; scopes: string[] } = {
  stopLoopingRedirect: false,
  accessToken: undefined,
  scopes: [],
};

const signInScopes: string[] = [...REFRESH_TOKEN_SCOPES];
const accessTokenScopes: string[] = [...REFRESH_TOKEN_SCOPES];

const config: Msal.Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: SIGN_IN_AUTHORITY,
    redirectUri: LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: window.location.origin,
    knownAuthorities: [extractHostnameFromUrl(B2C_DOMAIN)],
  },
  system: {
    loggerOptions: { logLevel: Msal.LogLevel.Warning, loggerCallback },
    allowNativeBroker: false
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
};
const localMsalApp = new Msal.PublicClientApplication(config);

const saveAccessToken = (accessToken: string) => {
  const localUser = JSON.parse(localStorage.getItem(MERLIN) || '{}');
  localStorage.setItem(MERLIN, JSON.stringify({
    ...localUser,
    loginToken: accessToken,
  }));
};

const extractEmailFromIdTokenClaims = (idTokenClaims: IdTokenClaims) => {
  if (idTokenClaims.email) {
    return idTokenClaims.email;
  } else if (idTokenClaims.other_mails) {
    return idTokenClaims.other_mails[0];
  } else {
    return undefined;
  }
};

const saveUserInfo = (userInfo: GetUserInfoRes, idTokenClaims: IdTokenClaims) => {
  const localUser = JSON.parse(localStorage.getItem(MERLIN) || '{}');
  localStorage.setItem(MERLIN, JSON.stringify({
    ...localUser,
    userName: userInfo.name,
    lastName: userInfo.surname === 'null' ? null : userInfo.surname,
    firstName: userInfo.givenName === 'null' ? null : userInfo.givenName,
    jobTitle: userInfo.jobTitle === 'null' ? null : userInfo.jobTitle,
    email: extractEmailFromIdTokenClaims(idTokenClaims),
    role: userInfo.role,
    organizationId: idTokenClaims['organization_id'],
    mobile: idTokenClaims.mobile,
    idp: idTokenClaims.idp,
  }));
  userStore.initUser();
  userStore.azureAccessToken = idTokenClaims['idp_access_token'];
};


const initManagementConsole = async (authenticationResult: Msal.AuthenticationResult, successCallback?: Function) => {
  saveAccessToken(authenticationResult.accessToken);
  try {
    await Services.createOrganizationAndUser();
    const userInfoRes = await Services.getUserInfo();
    saveUserInfo(userInfoRes.data, authenticationResult.idTokenClaims as IdTokenClaims);
    await userStore.getMetaInfo();
    userEventSender.setUserId(userInfoRes.data.email);
    if (successCallback) {
      successCallback();
    }
  } catch (err) {
    displayErrorPage('Init Management Console Error: ', err);
  }
};

const getB2CActiveAccount = (): Msal.AccountInfo & { idTokenClaims: IdTokenClaims } | undefined => {
  const allUsers = localMsalApp.getAllAccounts();
  let activeUser;
  if (allUsers.length > 0) {
    allUsers.forEach((user) => {
      const idTokenClaims = user.idTokenClaims as IdTokenClaims;
      if (idTokenClaims && B2C_ID_TOKEN_ACR.includes(idTokenClaims.acr)) {
        activeUser = user;
      }
    });
  }
  return activeUser;
};

const acquireToken = async (successCallback?: Function) => {
  const activeUser = getB2CActiveAccount();

  if (!activeUser) {
    if (window.location.pathname.includes('/login')) {
      try {
        // search in database or take the path as domain hint
        const domainHint = window.location.pathname.split('/login/')[1];
        // call the backend to check
        await Services.searchSSOConfig({ organizationId: domainHint });
        await localMsalApp.loginRedirect({
          scopes: state.scopes,
          domainHint,
          authority: OKTA_CUSTOM_SSO_AUTHORITY,
          redirectStartPage: LOGIN_REDIRECT_URL,
        });
      } catch (err) {
        displayErrorPage('search domain hint and login redirect failed: ', err);
      }
    } else {
      try {
        // general redirect
        await localMsalApp.loginRedirect({
          scopes: state.scopes,
        });
      } catch (err) {
        displayErrorPage('General Redirect failed', err);
      }
    }
  } else {
    try {
      // silent acquire token
      console.debug('try to silence acquire token for active user', activeUser);
      const authenticationResult: Msal.AuthenticationResult = await localMsalApp.acquireTokenSilent({
        scopes: accessTokenScopes,
        // authority: `https://${activeUser.environment}/${TENANT_ID}/${activeUser.idTokenClaims.acr}`,
        account: activeUser,
      });
      await initManagementConsole(authenticationResult, successCallback);
    } catch {
      // redirect acquire token
      try {
        await localMsalApp.acquireTokenRedirect({
          scopes: accessTokenScopes,
          // authority: `https://${activeUser.environment}/${TENANT_ID}/${activeUser.idTokenClaims.acr}`,
          account: activeUser,
        });
      } catch (err) {
        await localMsalApp.logoutRedirect(); 
      }
    }
  }
};


const eventCallback: Msal.EventCallbackFunction = async (message: Msal.EventMessage) => {
  if (message.error instanceof Msal.AuthError) {
    const authErr = message.error;
    
    if (authErr.errorMessage.includes('AADB2C90118')) {
      await localMsalApp.loginRedirect({ scopes: signInScopes, authority: RESET_PASSWORD_AUTHORITY });
    } else if (authErr.errorMessage.includes('AADB2C90091')) { // user cancel signup error
      await localMsalApp.loginRedirect({ scopes: signInScopes, authority: SIGN_IN_AUTHORITY });
    } else if (authErr.errorMessage.includes('AADB2C')) {
      // all other B2C error display the error page
      displayErrorPage('AADB2C Error: ', authErr);
    }
  }
};

const authentication = {
  getInstance: () => {
    return localMsalApp;
  },
  getB2CActiveAccount: getB2CActiveAccount,
  initialize: () => {
    state.scopes = signInScopes;
    if (!state.scopes || state.scopes.length === 0) {
      console.debug('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens');
      state.stopLoopingRedirect = true;
    }

    localMsalApp.addEventCallback(eventCallback);
  },
  run: async () => {
    if (window.location.pathname === '/exception' || window.location.pathname === '/activation') {
      // display error page directly
    } else {
      try {
        await localMsalApp.initialize();

        const handleRedirectPromiseRes = await localMsalApp.handleRedirectPromise(window.location.hash);
     
        if (handleRedirectPromiseRes !== null) {
          console.debug('handleRedirectPromiseRes is not null');
          await initManagementConsole(handleRedirectPromiseRes);
        }
        if (handleRedirectPromiseRes === null && window.parent === window) {
          console.debug('handleRedirectPromiseRes is null');
          if (!state.stopLoopingRedirect) {
            await acquireToken();
          }
        }
      } catch (err) {
        displayErrorPage('Authentication run failed', err);
      }
    }
  },
  signOut: async () => await localMsalApp.logoutRedirect(),
  refreshAccessToken: async (callback?: Function) => {
    console.debug('refresh access token');
    await localMsalApp.acquireTokenSilent({
      scopes: signInScopes,
      account: getB2CActiveAccount(),
      forceRefresh: true,
    }).then((authenticationResult) => {
      saveAccessToken(authenticationResult.accessToken);
      if (callback) {
        callback();
      }
    }).catch((err) => {
      if (err) {
        localMsalApp.logoutRedirect();
      }
    });
  },

  editProfile: async () => {
    await localMsalApp.loginRedirect({ scopes: signInScopes, authority: EDIT_PROFILE_AUTHORITY });
  },
  resetPassword: async () => {
    await localMsalApp.loginRedirect({ scopes: signInScopes, authority: RESET_PASSWORD_AUTHORITY });
  },
  changePassword: async () => {
    await localMsalApp.loginRedirect({ scopes: signInScopes, authority: CHANGE_PASSWORD_AUTHORITY });
  },
};

export default authentication;
