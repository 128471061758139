import { message, Modal } from 'antd';
import { action, observable } from 'mobx';
import moment from 'moment';
import authb2c from '../auth/reactAzureAdb2c';
import { ACTIVATE_USER } from '../common/api';
import { DEFAULT_PAGE_SIZE, MERLIN, OrgSubscription } from '../common/constants';
import { organizationMessage, settingMessage, userMessage } from '../i18n/i18n';
import { ActivateUserParam, InviteUserParam, MetaInfo, Organization, ResourceUsageInfo, Setting, UpdateUsageParams, UpdateUserParams, UserConfig, UserInfo, UserListRes } from '../services/model/user';
import Services from '../services/services';
import ErrorMs from '../types/ErrorMs';

const DEFAULT_SETTING = {
  filebeatEnabled: true,
  ssoConfig: {
    enabled: false,
  },
};
let trialExpireModal: any = null;
export class UserStore {
  private static instance: UserStore;

  public static getInstance() {
    if (!UserStore.instance) {
      UserStore.instance = new UserStore();
    }

    return UserStore.instance;
  }

  constructor() {
    this.initUser();
  }

  @observable user: Partial<UserInfo> = {};
  @observable setting: Setting = { isMFA: false, ssoConfig: { enabled: false }, filebeatEnabled: true }
  @observable metaInfo: Partial<MetaInfo> = {};
  @observable resourceUsage: Partial<ResourceUsageInfo> = {};
  @observable userList: Partial<UserListRes> = {};
  @observable organizationList: Array<Organization> = [];
  @observable currentOrganizationId: string | null = null;
  @observable azureAccessToken: string | null = null;
  @observable organizationInfo: Partial<Organization> = {};

  initUser() {
    this.user = JSON.parse(localStorage.getItem(MERLIN) || '{}');
    this.loadCurrentOrganizationId();
  }

  setCurrentOrganizationId(orgId: string) {
    if (this.user.role === 'SUPER_ADMIN') {
      this.currentOrganizationId = orgId;
      localStorage.setItem('CURRENT_ORG_ID', orgId);
    }
  }

  getPlan() {
    return this.metaInfo.plan || OrgSubscription.FREE;
  }

  planExpired() {
    // paid account never expire
    if (this.metaInfo.plan === OrgSubscription.PAID) {
      return false;
    }
    // then it should be free account, check days left
    if (this.metaInfo.leftDays !== undefined && this.metaInfo.leftDays <= 0) {
      if (!trialExpireModal && this.user.role !== 'SUPER_ADMIN') {
        trialExpireModal = Modal.confirm({
          title: 'Your free trial has ended',
          okText: 'Buy now',
          cancelText: 'Logout',
          closable: false,
          okCancel: true,
          onCancel: async (resolve, reject) => {
            await userStore.logout();
            reject();
          },
          cancelButtonProps: { danger: true, type: 'primary' },
          onOk: async (resolve, reject) => {
            window.location.href = 'https://www.datawiza.com/pricing/';
            reject();
          },
          autoFocusButton: null,
          okButtonProps: {
            style: {
              borderRadius: '4px'
            }
          },
          zIndex: 9999,
          keyboard: false
        });
      }
      
      return true;
    } else {
      // does not have any information about left days, or left days is larger than 0 
      return false;
    }
  }

  getCurrentOrganizationId() {
    return this.user.role === 'SUPER_ADMIN' ? this.currentOrganizationId : this.user.organizationId;
  }

  loadCurrentOrganizationId() {
    if (this.user.role === 'SUPER_ADMIN') {
      this.currentOrganizationId = localStorage.getItem('CURRENT_ORG_ID') ? localStorage.getItem('CURRENT_ORG_ID') : null;
    }
  }

  isSuperAdmin() {
    return this.user.role === 'SUPER_ADMIN';
  }

  setUser() {
    localStorage.setItem(MERLIN, JSON.stringify(this.user));
  }

  getToken() {
    this.user = JSON.parse(localStorage.getItem(MERLIN) || '{}');
    return this.user.loginToken;
  }

  setToken(token: string) {
    this.user.loginToken = token;
    localStorage.setItem(MERLIN, JSON.stringify(this.user));
  }

  getUserConfig(): UserConfig {
    return JSON.parse(localStorage.getItem('USER_CONFIG') || '{}');
  }

  setUserConfig(userConfig: UserConfig) {
    localStorage.setItem('USER_CONFIG', JSON.stringify({ ...this.getUserConfig(), ...userConfig }));
  }

  isDeploymentEmpty = () => {
    return this.metaInfo.deploymentCnt === 0;
  };

  async getMetaInfo() {
    try {
      const res = await Services.getMetaInfo();
      res.errCode === 0 ? this.metaInfo = Object.assign(this.metaInfo, res.data) : message.error(res.errMsg);
      await this.getUsageInfo();
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  }

  async logout() {
    try {
      await Services.logout();
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
    localStorage.removeItem(MERLIN);
    await authb2c.signOut();
  }

  async updateUserInfo(payload: UpdateUserParams) {
    await Services.updateUserInfo(payload).then(() => {
      this.user.firstName = payload.givenName;
      this.user.lastName = payload.surname;
      this.user.userName = payload.displayName;
      this.user.jobTitle = payload.jobTitle;
      this.setUser();
      message.success(userMessage.info.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  async getUsers(page: number, successCallback?: Function) {
    const usersRes = await Services.getUserList({ page, size: DEFAULT_PAGE_SIZE });
    const orgRes = await Services.getOrganization();
    this.userList = usersRes;
    this.organizationInfo = orgRes.data;
    if (successCallback) {
      successCallback();
    }
  }

  async getUsageInfo(successCallback?: Function) {
    try {
      const orgRes = await Services.getOrganization();
      const resourceRes = await Services.getResourceUsage();
      this.resourceUsage = resourceRes.data.resourceUsage;

      const res: MetaInfo = {
        organization: orgRes.data.name,
        organizationId: this.currentOrganizationId ? this.currentOrganizationId : this.user.organizationId,
        members: 1,
        plan: resourceRes.data.plan || 'FREE',
        deploymentsUsed: `${resourceRes.data.resourceUsage.DEPLOYMENT.usage}/${resourceRes.data.resourceUsage.DEPLOYMENT.maximum}`,
        applicationsUsed: `${resourceRes.data.resourceUsage.APPLICATION.usage}/${resourceRes.data.resourceUsage.APPLICATION.maximum}`,
        idpsUsed: `${resourceRes.data.resourceUsage.IDP.usage}/${resourceRes.data.resourceUsage.IDP.maximum}`,
        authorizersUsed: `${resourceRes.data.resourceUsage.AUTHORIZER.usage}/${resourceRes.data.resourceUsage.AUTHORIZER.maximum}`,
        rulesUsed: `${resourceRes.data.resourceUsage.RULE.usage}/${resourceRes.data.resourceUsage.RULE.maximum}`,
        leftDays: resourceRes.data.leftDays,
        trialExpire: moment(resourceRes.data.trialExpire),
      };

      this.metaInfo = { ...this.metaInfo, ...res };
      this.planExpired();
      if (successCallback) {
        successCallback(res);
      }
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  async updateOrgName(name: string) {
    try {
      await Services.updateOrganization({ name });
      this.organizationInfo.name = name;
      message.success(organizationMessage.updateSuccess);
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  }

  async getSetting() {
    await Services.getSettings().then((res) => {
      this.setting = { ...DEFAULT_SETTING, ...res.data };
    });
  }

  async updateSetting(data: any) {
    await Services.updateSettings(data).then(() => {
      message.success(settingMessage.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
      throw err;
    });
  }

  async inviteUser(payload: InviteUserParam, organizationId?: string) {
    try {
      const headers = organizationId ? { organizationId } : undefined;
      await Services.inviteUser(payload, headers);
      message.success(`Invitation email sent to ${payload.email} successfully`);
      this.getUsers(0);
    } catch (err) {
      console.log('error mes', err);
      if (err instanceof ErrorMs) {
        if (err.code === 6006) {
          message.error(userMessage.alreadyExisted);
        } else {
          message.error(err.message);
        }
      }
    }
  }

  handleData = async (response: Response) => {
    if (response.status === 201 || response.status === 200) {
      const data = await response.json();
      if (data.errCode === 0) {
        return data;
      } else {
        throw new ErrorMs(data.errMsg, data.errCode);
      }
    } else if (response.status === 403) {
      throw new ErrorMs('Unauthorized', 403);
    } else {
      const errorRes: {error: {code: string; message: string}} = await response.json();
      throw new ErrorMs(errorRes.error.message, errorRes.error.code);
    }
  }

  async activateUser(payload: ActivateUserParam, successCallback: Function) {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await fetch(ACTIVATE_USER, params);
    try {
      await this.handleData(response);
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (err instanceof ErrorMs) {
        if (err.code === 6005) {
          message.error(userMessage.activationTokenInvalid);
        } else {
          message.error(err.message);
        } 
      }
    }
  }

  async invalidateAllRefreshTokens() {
    await Services.invalidateAllRefreshTokens();
  }

  @action
  getOrganizations() {
    Services.getAllOrganizations().then((res) => {
      this.organizationList = res.data;
    }).catch((err) => {
      message.error(err.message);
    });
  }

  changeUserOrg(userId: string, newOrganizationId: string) {
    return Services.changeUserOrg({ userId, organizationId: newOrganizationId });
  }

  @action
  updateUsage(payload: UpdateUsageParams) {
    Services.updateMaxUsage(payload).then(() => {
      message.success(settingMessage.maximumUsage.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  async resetAuthenticator(userId: string) {
    await Services.resetAuthenticator({ userId }).then(() => {
      message.success(settingMessage.authenticator.resetSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  async deleteUser(userId: string) {
    await Services.deleteUser({ userId });
  }
}

export const userStore = UserStore.getInstance();
